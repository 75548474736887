:root {
  --separator-border: rgba(128, 128, 128, 0.35);
}

.allotment-module_splitView__L-yRc {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.allotment-module_splitView__L-yRc > .allotment-module_sashContainer__fzwJF {
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.allotment-module_splitView__L-yRc > .allotment-module_sashContainer__fzwJF > .allotment-module_sash__QA-2t {
  pointer-events: auto;
}

.allotment-module_splitView__L-yRc > .allotment-module_splitViewContainer__rQnVa {
  height: 100%;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.allotment-module_splitView__L-yRc > .allotment-module_splitViewContainer__rQnVa > .allotment-module_splitViewView__MGZ6O {
  overflow: hidden;
  position: absolute;
  white-space: initial;
}

.allotment-module_splitView__L-yRc.allotment-module_vertical__WSwwa > .allotment-module_splitViewContainer__rQnVa > .allotment-module_splitViewView__MGZ6O {
  width: 100%;
}

.allotment-module_splitView__L-yRc.allotment-module_horizontal__7doS8 > .allotment-module_splitViewContainer__rQnVa > .allotment-module_splitViewView__MGZ6O {
  height: 100%;
}

.allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS
  > .allotment-module_splitViewContainer__rQnVa
  > .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
  background-color: var(--separator-border);
  content: " ";
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 5;
}

.allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS.allotment-module_vertical__WSwwa
  > .allotment-module_splitViewContainer__rQnVa
  > .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
  height: 1px;
  width: 100%;
}

.allotment-module_splitView__L-yRc.allotment-module_separatorBorder__x-rDS.allotment-module_horizontal__7doS8
  > .allotment-module_splitViewContainer__rQnVa
  > .allotment-module_splitViewView__MGZ6O:not(:first-child)::before {
  height: 100%;
  width: 1px;
}

:root {
  --focus-border: #007fd4;
  --sash-size: 8px;
  --sash-hover-size: 4px;
}

.sash-module_sash__K-9lB {
  position: absolute;
  z-index: 35;
  touch-action: none;
  pointer-events: auto;
  text-align: initial;
}

.sash-module_sash__K-9lB.sash-module_disabled__Hm-wx {
  pointer-events: none;
}

.sash-module_sash__K-9lB.sash-module_mac__Jf6OJ.sash-module_vertical__pB-rs {
  cursor: col-resize;
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs.sash-module_minimum__-UKxp {
  cursor: e-resize;
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs.sash-module_maximum__TCWxD {
  cursor: w-resize;
}

.sash-module_sash__K-9lB.sash-module_mac__Jf6OJ.sash-module_horizontal__kFbiw {
  cursor: row-resize;
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_minimum__-UKxp {
  cursor: s-resize;
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_maximum__TCWxD {
  cursor: n-resize;
}

.sash-module_sash__K-9lB.sash-module_disabled__Hm-wx {
  cursor: default !important;
  pointer-events: none !important;
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs {
  cursor: ew-resize;
  top: 0;
  width: var(--sash-size);
  height: 100%;
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw {
  cursor: ns-resize;
  left: 0;
  width: 100%;
  height: var(--sash-size);
}

.sash-module_sash__K-9lB:not(.sash-module_disabled__Hm-wx) > .sash-module_orthogonal-drag-handle__Yii2- {
  content: " ";
  height: calc(var(--sash-size) * 2);
  width: calc(var(--sash-size) * 2);
  z-index: 100;
  display: block;
  cursor: all-scroll;
  position: absolute;
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_orthogonal-edge-north__f7Noe:not(.sash-module_disabled__Hm-wx)
  > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_start__uZEDk,
.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_orthogonal-edge-south__6ZrFC:not(.sash-module_disabled__Hm-wx)
  > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_end__0TP-R {
  cursor: nwse-resize;
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_orthogonal-edge-north__f7Noe:not(.sash-module_disabled__Hm-wx)
  > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_end__0TP-R,
.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw.sash-module_orthogonal-edge-south__6ZrFC:not(.sash-module_disabled__Hm-wx)
  > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_start__uZEDk {
  cursor: nesw-resize;
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_start__uZEDk {
  left: calc(var(--sash-size) * -0.5);
  top: calc(var(--sash-size) * -1);
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_end__0TP-R {
  left: calc(var(--sash-size) * -0.5);
  bottom: calc(var(--sash-size) * -1);
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_start__uZEDk {
  top: calc(var(--sash-size) * -0.5);
  left: calc(var(--sash-size) * -1);
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw > .sash-module_orthogonal-drag-handle__Yii2-.sash-module_end__0TP-R {
  top: calc(var(--sash-size) * -0.5);
  right: calc(var(--sash-size) * -1);
}

.sash-module_sash__K-9lB:before {
  content: "";
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: background-color 0.1s ease-out;
  background: transparent;
}

.sash-module_sash__K-9lB.sash-module_vertical__pB-rs:before {
  width: var(--sash-hover-size);
  left: calc(50% - (var(--sash-hover-size) / 2));
}

.sash-module_sash__K-9lB.sash-module_horizontal__kFbiw:before {
  height: var(--sash-hover-size);
  top: calc(50% - (var(--sash-hover-size) / 2));
}

.sash-module_sash__K-9lB.sash-module_hover__80W6I:before,
.sash-module_sash__K-9lB.sash-module_active__bJspD:before {
  background: var(--focus-border);
}
