@import "colors.scss";

// root
:root {
  --maxScreenWidth: 1600px;
  --minScreenWidth: 1020px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overscroll-behavior: none;
  min-width: var(--minScreenWidth);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

#root {
  position: relative;
  height: 100%;
}

#shoot {
  position: relative;
  z-index: 10;
}
